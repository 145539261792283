import { Dispatch } from 'react'
import { ThunkAction } from 'redux-thunk'
import { AppStateType } from '../store'
import { responseEmojiType, FetchEmojiType, PermissionsType, EventsTypeResp, EventsTypeReq } from '../../types/types'
import { setDrills, SetDrillsType } from './drills_reducer'
import { ChatAPI } from '../../api/chat'
const SET_MESSAGES = 'SET_MESSAGES'
const ADD_MESSAGE = 'ADD_MESSAGE'
const EDIT_MESSAGE = 'EDIT_MESSAGE'
const SET_PERMISSIONS = 'SET_PERMISSIONS'
const SET_EMOS = 'SET_EMOS'
const SET_LOAD = 'SET_LOAD'
const SET_USER_ROLES = 'SET_USER_ROLES'
const SET_CHAT_SETTINGS = 'SET_CHAT_SETTINGS'
const ADD_EMOJI = 'ADD_EMOJI'
const SET_NAME_DRILL = 'SET_NAME_DRILL'
const WAS_READED_GENERAL_CHAT = 'WAS_READED_GENERAL_CHAT'
const SET_NAME_LEADER = 'SET_NAME_LEADER'
const SET_ERROR = 'SET_ERROR'
const SET_STATUS_DRILL = 'SET_STATUS_DRILL'

let initialstate = {
  isActive: false,
  withAgreement: null as null | boolean,
  nameDrill: null as null | string,
  messages: [] as EventsTypeResp[],
  permissions: [] as PermissionsType[],
  emos: [] as responseEmojiType[],
  isLoad: null as null | boolean,
  isLeader: false,
  nameRole: null as null | string,
  socialRoleType: null as null | string,
  nameLeader: null as null | string,
  chatSettings: {
    authority: true,
    press: true,
    network: true,
  } as any,
  wasReadedGeneralChat: true,
  isError: false,
}

export type InitialstateType = typeof initialstate

const chat_reducer = (state = initialstate, action: ActionsType): InitialstateType => {
  switch (action.type) {
    case SET_MESSAGES: {
      return { ...state, messages: action.messages }
    }
    case SET_STATUS_DRILL: {
      return { ...state, isActive: action.isActive }
    }
    case ADD_MESSAGE: {
      let newArray = [...state.messages] //создаем новый массив
      let id = newArray.findIndex((x) => x.id === action.message.id)
      if (id != -1) {
        newArray[id] = action.message
      } else {
        newArray = [...state.messages, action.message]
      }
      return { ...state, messages: newArray }
    }
    case EDIT_MESSAGE: {
      const newArray = [...state.messages] //создаем новый массив
      let id = newArray.findIndex((x) => x.id === action.message.id)
      newArray[id] = action.message
      return { ...state, messages: newArray }
    }
    case SET_PERMISSIONS: {
      return { ...state, permissions: action.permissions }
    }
    case SET_EMOS: {
      return { ...state, emos: action.emos }
    }
    case SET_LOAD: {
      return { ...state, isLoad: action.status }
    }
    case SET_USER_ROLES: {
      return { ...state, isLeader: action.isLeader, nameRole: action.nameRole, socialRoleType: action.socialRoleType }
    }
    case SET_CHAT_SETTINGS: {
      return { ...state, chatSettings: action.chatSettings }
    }
    case SET_NAME_DRILL: {
      return { ...state, nameDrill: action.name, withAgreement: action.withAgreement }
    }
    case SET_NAME_LEADER: {
      return { ...state, nameLeader: action.name }
    }
    case ADD_EMOJI: {
      let newArray = [...state.emos] //создаем новый массив
      let id = newArray.findIndex(
        (x) => x.messageId === action.emoji.id && x.emo == action.emoji.emo && x.member == action.emoji.member,
      )
      if (id != -1) {
        newArray = newArray.filter((i, index) => index != id)
      } else {
        newArray = [
          ...state.emos,
          {
            drillId: action.emoji.drill,
            emo: action.emoji.emo,
            member: action.emoji.member,
            messageId: action.emoji.id,
          },
        ]
      }
      return { ...state, emos: newArray }
    }
    case WAS_READED_GENERAL_CHAT: {
      return { ...state, wasReadedGeneralChat: action.wasReaded }
    }
    case SET_ERROR: {
      return { ...state, isError: action.error }
    }
    default:
      return state
  }
}

export default chat_reducer

type ActionsType =
  | SetMessagesType
  | addMessage
  | editMessage
  | SetPermissionsType
  | SetEmojiType
  | setLoad
  | setUserRoleType
  | setChatSettingsType
  | SetDrillsType
  | addEmoji
  | SetNameDrill
  | wasReadGeneralChat
  | setNameLeader
  | setErrorFileType
  | setStatusDrill

type DispatchType = Dispatch<ActionsType>

type ThunkType = ThunkAction<Promise<void>, AppStateType, unknown, ActionsType>

type SetMessagesType = {
  type: typeof SET_MESSAGES
  messages: EventsTypeResp[]
}

type SetPermissionsType = {
  type: typeof SET_PERMISSIONS
  permissions: PermissionsType[]
}

type SetEmojiType = {
  type: typeof SET_EMOS
  emos: responseEmojiType[]
}

type SetNameDrill = {
  type: typeof SET_NAME_DRILL
  name: string
  withAgreement: boolean
}

export let setMessages = (messages: EventsTypeResp[]): SetMessagesType => {
  return {
    type: SET_MESSAGES,
    messages,
  }
}

export let setPermissions = (permissions: PermissionsType[]): SetPermissionsType => {
  return {
    type: SET_PERMISSIONS,
    permissions,
  }
}

export let setEmoji = (emos: responseEmojiType[]): SetEmojiType => {
  return {
    type: SET_EMOS,
    emos,
  }
}

export let setNameDrill = (name: string, withAgreement: boolean): SetNameDrill => {
  return {
    type: SET_NAME_DRILL,
    name,
    withAgreement,
  }
}

type setChatSettingsType = {
  type: typeof SET_CHAT_SETTINGS
  chatSettings: any
}

type setStatusDrill = {
  type: typeof SET_STATUS_DRILL
  isActive: boolean
}

export let setChatSettings = (chatSettings: any): setChatSettingsType => {
  return {
    type: SET_CHAT_SETTINGS,
    chatSettings,
  }
}

export let setUserRole = (isLeader: boolean, socialRoleType: string, nameRole: string): setUserRoleType => {
  return {
    type: SET_USER_ROLES,
    isLeader,
    socialRoleType,
    nameRole,
  }
}

export let setDrillStatus = (isActive: boolean): setStatusDrill => {
  return {
    type: SET_STATUS_DRILL,
    isActive,
  }
}

type setUserRoleType = {
  type: typeof SET_USER_ROLES
  isLeader: boolean
  socialRoleType: string
  nameRole: string
}

export let getMessages =
  (sessionID: string): ThunkType =>
  async (dispatch: DispatchType) => {
    let messagesData = await ChatAPI.getMessages(sessionID)
    'events' in messagesData ? dispatch(setMessages(messagesData.events.reverse())) : dispatch(setMessages([]))
    dispatch(setDrills([messagesData.drill]))
    'permissions' in messagesData ? dispatch(setPermissions(messagesData.permissions)) : dispatch(setMessages([]))
    'emos' in messagesData ? dispatch(setEmoji(messagesData.emos)) : dispatch(setEmoji([]))
    'isActive' in messagesData ? dispatch(setDrillStatus(messagesData.isActive)) : dispatch(setDrillStatus(false))
    //const isLeader
    //messagesData.pa
    dispatch(
      setUserRole(
        'otherRole' in messagesData ? true : false,
        messagesData.role.socialRoleType.$type,
        messagesData.role.name,
      ),
    )
    dispatch(
      setNameDrill(
        messagesData.drill.name,
        'withAgreement' in messagesData.drill ? messagesData.drill.withAgreement : false,
      ),
    )
    let nameLeader = messagesData.participants.find((participant) => participant.leader === true)

    dispatch(setNameLeader(nameLeader?.socialRole.name || null))
  }

export let sendMessages =
  (message: EventsTypeReq): ThunkType =>
  async (dispatch: DispatchType) => {
    let messagesData = await ChatAPI.sendMessage(message)
    console.log(messagesData)
  }

export let setNameLeader = (name: string | null): setNameLeader => {
  return {
    type: SET_NAME_LEADER,
    name,
  }
}

type setNameLeader = {
  type: typeof SET_NAME_LEADER
  name: string | null
}

type addMessage = {
  type: typeof ADD_MESSAGE
  message: EventsTypeResp
}

export let addMessage = (message: EventsTypeResp): addMessage => {
  return {
    type: ADD_MESSAGE,
    message,
  }
}

type editMessage = {
  type: typeof EDIT_MESSAGE
  message: EventsTypeResp
}

export let editMessage = (message: EventsTypeResp): editMessage => {
  return {
    type: EDIT_MESSAGE,
    message,
  }
}

export let preloadFile =
  (file: File): ThunkType =>
  async (dispatch: DispatchType) => {
    try {
      dispatch(setLoader(false))
      let resp = await ChatAPI.preloadFile(file)
      if (resp === 'File uploaded') {
        dispatch(setLoader(true))
      }
    } catch (error) {
      dispatch(setErrorFile(true))
      dispatch(setLoader(true))
    }
  }

type setLoad = {
  type: typeof SET_LOAD
  status: boolean | null
}

export let setLoader = (status: boolean | null): setLoad => {
  return {
    type: SET_LOAD,
    status,
  }
}

export let deleteFile =
  (contentType: string, fileName: string): ThunkType =>
  async (dispatch: DispatchType) => {
    let resp = await ChatAPI.deleteFile(contentType, fileName)
    dispatch(setLoader(null))
  }

export let fileSave =
  (file: string, contentType: string, fileName: string, wsChannel: WebSocket | null): ThunkType =>
  async (dispatch: DispatchType) => {
    let resp = await ChatAPI.fileSave(file, contentType, fileName)
    dispatch(setLoader(null))
  }

type addEmoji = {
  type: typeof ADD_EMOJI
  emoji: FetchEmojiType
}

export let addEmoji = (emoji: FetchEmojiType): addEmoji => {
  return {
    type: ADD_EMOJI,
    emoji,
  }
}

type wasReadGeneralChat = {
  type: typeof WAS_READED_GENERAL_CHAT
  wasReaded: boolean
}

export let wasReadGeneralChat = (wasReaded: boolean): wasReadGeneralChat => {
  return {
    type: WAS_READED_GENERAL_CHAT,
    wasReaded,
  }
}

type setErrorFileType = {
  type: typeof SET_ERROR
  error: boolean
}

export let setErrorFile = (error: boolean): setErrorFileType => {
  return {
    type: SET_ERROR,
    error,
  }
}
