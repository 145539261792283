import { instance } from './instance'
import { Session } from '../types/chat-api-types'
import { EventsTypeReq } from '../types/types'
// import { EventsType, responsMessageType, RightsType } from '../types/types'
// import { PermissionType } from '../pages/rights-page/TableRights'

export type editMessageType = {
  message?: string
  messageType?: string
  file?: string
}

export const ChatAPI = {
  getMessages(sessionID: string) {
    return instance.get<Session>(`/api/session/` + sessionID).then((res) => res.data)
  },
  sendMessage(message: EventsTypeReq) {
    return instance.post('/api/chat/message', message).then((res) => res.data)
  },
  deleteMessage(id: string) {
    return instance.delete('/api/chat/message/' + id).then((res) => res.data)
  },
  editMessage(id: string, message: editMessageType) {
    return instance.patch('/api/chat/message/' + id, message).then((res) => res.data)
  },
  agreeMessage(id: string) {
    return instance.post('/api/chat/agreeMessage/' + id).then((res) => res.data)
  },
  denyMessage(id: string) {
    return instance.post('/api/chat/denyMessage/' + id).then((res) => res.data)
  },
  preloadFile(file: File) {
    let formData = new FormData()
    formData.append('file', file)
    return instance
      .post<string>('/api/drill/chat-message-file-preload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => res.data)
  },
  deleteFile(contentType: string, fileName: string) {
    return instance.post('/api/drill/delete-attache-file', { contentType, fileName }).then((res) => res.data)
  },
  fileSave(id: string, contentType: string, fileName: string) {
    return instance.post('/api/drill/chat-message-file-save', { id, contentType, fileName }).then((res) => res.data)
  },
}
